@import "./vars";
@import "./_mixins";

html {
    height: 100%;
    overflow-x: hidden;
}
body {
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}
#root {
    height: 100%;
    > div {
        height: 100%;
    }
}
.page {
    @include flex-column;
    min-height: 100%;
    main.main {
        flex: 1 0 auto;
    }
}

*,
::before,
::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: inherit;
}
input,
button,
label,
select {
    outline: none;
    margin: 0;
    font-family: inherit;
}
button {
    cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: inherit;
    margin: 0;
    padding: 0;
}
ul,
ol {
    li {
        font-family: inherit;
        padding: 0;
        margin: 0;
    }
}
img {
    max-width: 100%;
}

.container {
    max-width: 1460px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    @media all and (max-width: $normalDesktop) {
        max-width: 1100px;
    }
}

.current-analytic-page {
    background: linear-gradient(170.79deg, #151a2c 0.37%, #120d20 93.02%);
    section {
        padding-bottom: 100px;
    }
}
.mainPage {
    // background: url('../Assets/images/full-bg.png');
    background-position: top left;
    background-size: cover;
    // background-blend-mode: color-dodge;
    section {
        padding-bottom: 100px;
        @media all and (max-width: $tablet) {
            padding-bottom: 0;
            &:last-child {
                padding-bottom: 40px;
            }
        }
    }
}
.title {
    @include fontRem(44, 700);
    line-height: 54px;
    letter-spacing: 0.055em;
    color: $titleColor;
    text-align: center;
    margin-bottom: 32px;
    max-width: 952px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media all and (max-width: $normalDesktop) {
        @include fontRem(32, 700);
        line-height: 40px;
        margin-bottom: 24px;
        max-width: 752px;
    }
    @media all and (max-width: $tablet) {
        @include fontRem(24, 700);
        line-height: 30px;
        margin-bottom: 16px;
        max-width: 302px;
    }
    &.dashboard-title {
        @include fontRem(32, 700);
        line-height: 40px;
        text-align: left;
        max-width: unset;
        margin-left: 0;
        margin-bottom: 20px;
        @media all and (max-width: $tablet) {
            margin-top: 30px;
        }
        @media all and (max-width: $smallTablet) {
            @include fontRem(24, 700);
            line-height: 29px;
            margin-bottom: 32px;
        }
    }
}
.dashboard-sub {
    @include fontRem(24, 400);
    line-height: 33px;
    letter-spacing: 0.055em;
    color: $titleColor;
    margin-bottom: 32px;
    @media all and (max-width: $smallTablet) {
        margin-top: -32px;
        margin-bottom: 32px;
        @include fontRem(16, 400);
    }
}
.section_inner {
    background: rgba(41, 52, 139, 0.16);
    border-radius: 8px;
    &.promocodeSection_inner,
    &.forecastListSecton_inner {
        @media all and (max-width: $smallTablet) {
            background-color: transparent;
        }
    }
}
.subTitle {
    @include fontRem(18, 400);
    line-height: 24px;
    text-align: center;
    color: $titleColor;
    max-width: 752px;
    margin-left: auto;
    margin-right: auto;
    @media all and (max-width: $normalDesktop) {
        @include fontRem(16, 400);
        line-height: 22px;
        max-width: 630px;
    }
    @media all and (max-width: $tablet) {
        @include fontRem(14, 400);
        line-height: 20px;
        max-width: 320px;
    }
}
.defualtCheck {
    label {
        padding-left: 24px;
        cursor: pointer;
        position: relative;
        transition: 0.3s;
        &::before {
            content: "";
            width: 24px;
            height: 24px;
            display: flex;
            border: 1px solid rgba(178, 186, 215, 0.32);
            box-sizing: border-box;
            border-radius: 4px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            @media all and (max-width: $smallTablet) {
                top: 0;
                transform: translateY(0);
            }
        }
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translate(-50%, -50%);
            width: 16px;
            height: 10px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 4L8 10.5L17.5 1' stroke='%23215AE7' stroke-width='2'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
            transition: 0.3s;
            visibility: hidden;
            opacity: 0;
        }
    }
    input {
        display: none;
        &:checked {
            + label {
                &::before {
                    border-color: $blue;
                }
                &::after {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}
.wrapperPortal {
    .nftCircle1,
    .stockCircle1,
    .crytoCircle1,
    .binanceCircle1 {
        animation: animateCirlce 2s linear infinite;
        animation-direction: alternate;
        animation-delay: 1s;
        @keyframes animateCirlce {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
    .nftCircle2,
    .stockCircle2,
    .crytoCircle2,
    .binanceCircle2 {
        animation: animateCirlce2 2s linear infinite;
        animation-direction: alternate;
        animation-delay: 0s;
        @keyframes animateCirlce2 {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
    .pathsEvents {
        pointer-events: none;
    }
    .controlCirlce {
        cursor: pointer;
        &:hover {
            fill: #18254a;
            transition: 0.3s;
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
