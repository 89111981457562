@import "/src/styles/vars";
@import "/src/styles/mixins";

.header {
    background: rgba(20, 21, 39, 0.24);
    backdrop-filter: blur(12px);
    position: sticky;
    top: 0;
    z-index: 20;
    transition: 0.3s;
    @media all and (max-width: $tablet) {
        height: 64px;
        backdrop-filter: unset;
        @include flex-center-vert;
    }
    &.scrolled {
        background: rgba(20, 21, 39, 0.88);
        transition: 0.3s;
    }
    [class="container"] {
        max-width: 100%;
        padding-left: 80px;
        padding-right: 80px;
        @media all and (max-width: $normalDesktop) {
            padding-left: 56px;
            padding-right: 56px;
        }
        @media all and (max-width: $tablet) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .headerLogo {
        img {
            width: 169px;
            @media all and (max-width: $tablet) {
                width: 169px;
            }
            @media all and (max-width: $smallTablet) {
                width: 100px;
            }
        }
        cursor: pointer;
    }
    .nav {
        @include flex-center-vert;

        .headerMenu {
            margin-left: auto;
            .headerMenuList {
                @include flex-center-vert;
                list-style-type: none;
                li {
                    position: relative;
                    padding: 23px 0;
                    .langSubList {
                        visibility: hidden;
                        opacity: 0;
                        position: absolute;
                        top: 130%;
                        right: 0;
                        width: 100%;
                        min-width: 130px;
                        list-style-type: none;
                        transition: 0.3s;
                        background: rgba(41, 52, 139, 0.24);
                        box-shadow: 4px 8px 24px rgba(19, 20, 38, 0.16);
                        backdrop-filter: blur(16px);
                        border-radius: 8px;
                        padding: 8px 6px;
                        @media all and (max-width: $tablet) {
                            padding: 8px 6px;
                        }
                        li {
                            padding: 0;
                            margin-left: 0;
                            &:not(:first-child) {
                                margin-top: 8px;
                                @media all and (max-width: $tablet) {
                                    margin-top: 0;
                                }
                            }
                        }
                        .langSubListLink {
                            display: block;
                            cursor: pointer;
                            text-align: center;
                            @include fontRem(16, 400);
                            color: rgba(227, 230, 234, 0.32);
                            transition: 0.3s;
                            line-height: 22px;
                            text-decoration: none;
                            border-radius: 8px;
                            padding: 8px;
                            &:hover {
                                color: $white;
                                background: rgba(41, 52, 139, 0.32);
                            }
                            &.selected {
                                color: $white;
                                background: rgba(41, 52, 139, 0.32);
                            }
                        }
                        @supports (
                            mask: linear-gradient(#fff 0 0) content-box,
                            linear-gradient(
                                #fff 0 0,
                            )
                        ) {
                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                border-radius: 8px;
                                padding: 1px;
                                background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
                                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                                mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                                -webkit-mask-composite: xor;
                                mask-composite: exclude;
                            }
                        }
                    }
                    &:hover {
                        .iconWrapper {
                            transform: rotate(180deg);
                        }
                        .langSubList {
                            top: 80%;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    &:not(:first-child) {
                        margin-left: 40px;
                        @media all and (max-width: $normalDesktop) {
                            margin-left: 18px;
                        }
                    }
                    .headerMenuLink {
                        @include flex-center-vert;
                        @include fontRem(16, 400);
                        line-height: 20px;
                        cursor: pointer;
                        color: $white;
                        text-decoration: none;
                        padding-top: 12px;
                        padding-bottom: 12px;
                        transition: 0.3s;
                        @media all and (max-width: $normalDesktop) {
                            @include fontRem(14, 400);
                        }
                        .iconWrapper {
                            margin-left: 12px;
                            transition: 0.3s;
                            svg {
                                @media all and (max-width: $normalDesktop) {
                                    width: 12px;
                                }
                            }
                        }
                        &.headerSignIn {
                            margin-left: 24px;
                            &:hover {
                                text-decoration: none;
                            }
                            @media all and (max-width: $normalDesktop) {
                                margin-left: 18px;
                            }
                        }
                        &.signupBtn {
                            @media all and (max-width: $tablet) {
                                padding-bottom: 0;
                            }
                        }
                        &.active {
                            color: $blue;
                        }
                        &:hover {
                            @media (any-hover: hover) {
                                color: $blue;
                            }
                        }
                    }
                }
            }
            @media all and (max-width: $tablet) {
                position: fixed;
                top: 34px;
                left: 0;
                width: 100%;
                height: 100vh;
                background: rgba(20, 21, 40, 0.94);
                backdrop-filter: blur(6px);
                visibility: hidden;
                opacity: 0;
                transition: 0.3s;
                &.active {
                    visibility: visible;
                    opacity: 1;
                    top: 64px;
                }
                .headerMenuList {
                    padding-top: 48px;
                    overflow: auto;
                    height: 100%;
                    @include flex-center-column;
                    justify-content: flex-start;
                    > li {
                        padding: 0;
                        &:not(:first-child) {
                            margin-left: 0;
                        }
                        .headerMenuLink {
                            padding-top: 0;
                            padding-bottom: 32px;
                            &.headerSignIn {
                                margin-top: 18px;
                                margin-left: 0;
                                padding-bottom: 24px;
                            }
                        }
                        &:last-child {
                            @media all and (max-width: $tablet) {
                                padding-bottom: 12px;
                            }
                            .headerMenuLink {
                                padding-bottom: 0;
                                // padding-top: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
    .headerBurger {
        margin-left: 34px;
        @media all and (max-width: $smallTablet) {
            margin-left: 26px;
        }
        width: 24px;
        height: 24px;
        @include flex-center;
        cursor: pointer;
        display: none;
        @media all and (max-width: $tablet) {
            display: flex;
        }
        .burgerIcon {
            display: block;
            position: relative;
            background-color: $white;
            width: 20px;
            height: 2px;
            &::before {
                content: "";
                position: absolute;
                top: -6px;
                left: 0;
                width: 20px;
                height: 2px;
                background-color: $white;
                transition: transform 0.3s, top 0.3s ease-in 0.3s;
            }
            &::after {
                content: "";
                position: absolute;
                top: 6px;
                left: 0;
                width: 20px;
                height: 2px;
                background-color: $white;
                transition: transform 0.3s, top 0.3s ease-in 0.3s;
            }
        }
        &.active {
            .burgerIcon {
                background-color: transparent;
                &::before {
                    transform: rotate(45deg);
                    top: 0;
                    transition: top 0.3s, transform 0.3s ease-in 0.3s;
                }
                &::after {
                    transform: rotate(-45deg);
                    top: 0;
                    transition: top 0.3s, transform 0.3s ease-in 0.3s;
                }
            }
        }
    }
    .headerAuthComponent {
        list-style-type: none;
        .userItem {
            cursor: pointer;
            position: relative;
            .userInfoShort {
                min-width: 150px;
                display: flex;
                justify-content: center;
                padding: 16px 16px 16px 20px;
                background: rgba(33, 90, 231, 0.08);
                border-radius: 8px;
                height: 48px;
              
                @media all and (max-width: $normalDesktop) {
                    padding: 16px 5px 16px 5px;
                }
                @include flex-center-vert;
                .nameShort {
                    margin-left: 14px;
                    max-width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-right: 16px;
                    @include fontRem(16, 400);
                    line-height: 20px;
                    color: $titleColor;
                    @media all and (max-width: $mobile) {
                        max-width: 100px;
                        @include textShorten(1);
                        @include fontRem(14, 400);
                    }
                        
                }
                .userDropdownIcon {
                    transition: 0.3s;
                }
                .userDropdownIconRotated {
                    transform: rotate(180deg);
                }
            }
            .userSubList {
                list-style-type: none;
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 130%;
                right: 0;
                width: 100%;
                list-style-type: none;
                transition: 0.3s;
                background: #212149;
                box-shadow: 4px 8px 24px rgba(19, 20, 38, 0.16);
                backdrop-filter: blur(16px);
                border-radius: 8px;
                // padding: 8px 12px;
                // min-width: 175px;
                left: 50%;
                transform: translateX(-50%);
                @media all and (max-width: $tablet) {
                    padding: 8px 6px;
                }
                @media all and (max-width: $smallTablet) {
                    // padding: 8px 32px;
                }
                &.userSubListOpen {
                    visibility: visible;
                    opacity: 1;
                    top: calc(100% - 11px);
                    @media all and (max-width: $tablet) {
                        top: 130%;
                    }
                }
                li {
                    padding: 0;
                    margin-left: 0;
                    &:not(:first-child) {
                        margin-left: 0;
                        margin-top: 8px;
                        @media all and (max-width: $tablet) {
                            margin-top: 0;
                        }
                    }
                    // &:last-child {
                    //     padding-top: 8px;
                    //     border-top: 1px solid rgba(182, 193, 255, 0.12);
                    // }
                    &.cointListItem {
                        margin-bottom: 13px;
                        padding-bottom: 13px;
                        border-bottom: 1px solid rgba(182, 193, 255, 0.12);
                        display: flex;
                        justify-content: center;
                        .cointItem {
                            min-width: unset;
                            margin-left: 0;
                            width: auto;
                            display: inline-flex;
                        }
                    }
                }
                .userSubLink {
                    display: block;
                    cursor: pointer;
                    text-align: center;
                    @include fontRem(16, 400);
                    color: rgba(227, 230, 234, 0.32);
                    transition: 0.3s;
                    line-height: 22px;
                    text-decoration: none;
                    border-radius: 8px;
                    padding: 8px;
                    
                    &:hover {
                        color: $white;
                        background: rgba(41, 52, 139, 0.32);
                    }
                    &.selected {
                        color: $white;
                        background: rgba(41, 52, 139, 0.32);
                    }
                }
                @supports (
                    mask: linear-gradient(#fff 0 0) content-box,
                    linear-gradient(
                        #fff 0 0,
                    )
                ) 
                {
                    // &::before {
                    //     content: "";
                    //     position: absolute;
                    //     top: 0;
                    //     left: 0;
                    //     right: 0;
                    //     bottom: 0;
                    //     border-radius: 8px;
                    //     padding: 1px;
                    //     background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
                    //     -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                    //     mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                    //     -webkit-mask-composite: xor;
                    //     mask-composite: exclude;
                    // }
                }
            }
        }
    }
    .cointItem {
        position: relative;
        padding: 7px 6px;
        height: 48px;
        border-radius: 8px;
        background: rgba(33, 90, 231, 0.08);
        @include flex-center-vert;
        @include fontRem(16, 500);
        line-height: 20px;
        color: $white;
        min-width: 155px;
        @media all and (max-width: $normalDesktop) {
            min-width: unset;
        }
        span {
            display: flex;
            margin-right: 15px;
            
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 8px;
            padding: 2px;
            background: linear-gradient(rgba(24, 117, 252, 1), rgba(0, 221, 251, 1));
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
        }
    }
    .burgerFullContainer {
        @include flex-center-vert;
        margin-left: auto;
        .tabletItem {
            @include flex-center-vert;
            .cointItem {
                padding: 10px 12px;
                margin-left: 24px;
            }
            .userSubList {
                left: 50%;
                transform: translateX(-50%);
                
                
            }
        }
    }
}
