@import "/src/styles/vars";
@import "/src/styles/mixins";

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(170.79deg, rgba(21, 26, 44, 0.92) 0.37%, rgba(18, 13, 32, 0.92) 93.02%);
    backdrop-filter: blur(16px);
    z-index: 33;
}

.modalClass {
    max-width: 660px;
    width: 100%;
    margin: 1.75rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    height: calc(100% - 3rem);
    outline: none;
    position: relative;
    z-index: 34;
    @media all and (max-width: $smallTablet) {
        margin-left: 16px;
        margin-right: 16px;
        max-width: 100%;
    }
}
.modalInner {
    pointer-events: all;
    background: rgba(41, 52, 139, 0.24);
    backdrop-filter: blur(24px);
    border-radius: 8px;
    width: 100%;
    height: auto;
    max-height: 630px;
    overflow: auto;
    padding: 48px 24px 38px;
    @media all and (max-width: $smallTablet) {
        padding-left: 8px;
        padding-right: 8px;
    }
    &::-webkit-scrollbar {
        width: 6px;
        border-radius: 10px;
        @media all and (max-width: $tablet) {
            width: 3px;
        }
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: linear-gradient(229.09deg, rgba(173, 179, 189, 0.24) 3.76%, rgba(104, 115, 117, 0.24) 115.42%);
    }
    &::-webkit-scrollbar-thumb {
        background: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
        border-radius: 10px;
    }
    .modalCloser {
        position: absolute;
        top: 28px;
        right: 28px;
        cursor: pointer;
        @media all and (max-width: $smallTablet) {
          top: 20px;
          right: 20px;
        }
    }
}
