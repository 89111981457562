@import "/src/styles/vars";
@import "/src/styles/mixins";

.promocoSection {
    padding: 28px 24px;
    background: rgba(41, 52, 139, 0.16);
    border-radius: 8px;
    position: relative;
    @media all and (max-width: $tablet) and (min-width: $realTablet) {
        padding: 16px 10px;

    }
    @media all and (max-width: $smallTablet) {
        padding: 2px 8px 16px;
        background-color: transparent;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 8px;
        padding: 1px;
        background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        @media all and (max-width: $smallTablet) {
            content: none;
        }
    }
    .promocodeRow {
        @include flexRow(12, 3);
        position: relative;
        z-index: 2;
        @media all and (max-width: $smallTablet) {
            @include flexRow(12, 1);
            background: rgba(41, 52, 139, 0.16);
            padding-bottom: 16px;
            border-radius: 8px;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 8px;
                padding: 1px;
                background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
            }
        }
        @media all and (max-width: $tablet) and (min-width: $realTablet) {
            @include flexRow(4, 3);
        }
        .promocodeItem {
            padding: 24px 42px;
            background: rgba(41, 52, 139, 0.32);
            border-radius: 8px;
            @media all and (max-width: $bigDesktop) {
                padding: 20px;
            }
            @media all and (max-width: $normalDesktop) {
                padding: 15px 20px;
                height: 100%;
            }
            @media all and (max-width: $tablet) and (min-width: $realTablet) {
                padding: 12px 14px;
            }
            @media all and (max-width: $smallTablet) {
                padding: 12px 16px;
                margin-top: 14px;
                height: auto;
            }
            .UPTCount {
                display: flex;
                align-items: flex-start;
                .Logo {
                    margin-right: 24px;
                    @media all and (max-width: $normalDesktop) {
                        margin-right: 10px;
                    }
                    @media all and (max-width: $smallTablet) {
                        margin-right: 16px;
                        svg {
                            width: 28px;
                            height: 28px;
                        }
                    }
                }
                .Content {
                    .Count {
                        @include fontRem(36, 600);
                        line-height: 44px;
                        margin-bottom: 19px;
                        color: $titleColor;
                        @media all and (max-width: $bigDesktop) {
                            @include fontRem(30, 600);
                        }
                        @media all and (max-width: $normalDesktop) {
                            @include fontRem(22, 600);
                            line-height: 36px;
                            margin-bottom: 0;
                        }
                        @media all and (max-width: $tablet) and (min-width: $realTablet) {
                            @include fontRem(18, 600);
                        }
                        @media all and (max-width: $smallTablet) {
                            @include fontRem(24, 600);
                            line-height: 30px;
                            margin-bottom: 0;
                        }
                    }
                    .Text {
                        @include fontRem(16, 500);
                        line-height: 20px;
                        color: rgba(251, 252, 255, 0.64);
                        @media all and (max-width: $bigDesktop) {
                            @include fontRem(14, 500);
                        }
                        @media all and (max-width: $normalDesktop) {
                            @include fontRem(12, 500);
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .promocodeRowBody {
        @include flexRow(12, 2);
        margin-top: 30px;
        position: relative;
        z-index: 1;
        @media all and (max-width: $tablet) and (min-width: $realTablet) {
            margin: 30px 0;
            display: block;
            .promocodeCol {
                @include flexRow(10, 2); 
                 
            }
        }
        @media all and (max-width: $smallTablet) {
            margin-top: 8px;
            @include flexRow(12, 1);
        }
        [class*="col_"] {
            @media all and (max-width: $smallTablet) {
                &:first-child {
                    padding: 0;
                }
            }
        }
        .reffrealLinkInfoLeft {
            width: 100%;
        }
        .reffrealLinkInfoLogo {
            margin-right: 24px;
            @media all and (max-width: $normalDesktop) {
                margin-right: 15px;
            }
        }
        .reffrealLinkInfo,
        .reffrealLinkInfoLeft {
            .refferalLink {
                text-decoration: underline;
                text-transform: uppercase;
                @include fontRem(18, 600);
                line-height: 44px;
                margin-bottom: 8px;
                color: $titleColor;
                @media all and (max-width: $normalDesktop) {
                    @include fontRem(16, 600);
                    margin-bottom: 0;
                }
                @media all and (max-width: $smallTablet) {
                    @include fontRem(16, 600);
                    line-height: 1.5;
                }
            }
            .refferealLinkText {
               
                @include fontRem(18, 600);
                line-height: 14px;
                color: $titleColor;
                @media all and (max-width: $normalDesktop) {
                    @include fontRem(16, 600);
                }
                @media all and (max-width: $smallTablet) {
                    @include fontRem(12, 500);
                    margin-top: 8px;
                    color: rgba(251, 252, 255, 0.72);
                    opacity: 0.64;
                }
            }
        }
        .linkCopy {
            align-self: center;
            margin-top: -20px;
            margin-left: 10px;
            @media all and (max-width: $normalDesktop) {
                margin-top: -10px;
            }
            @media all and (max-width: $smallTablet) {
                margin-left: auto;
            }
            button {
                @include buttonNulled;
            }
        }
        .promocodeItemBig {
            padding: 24px 42px;
            background: rgba(41, 52, 139, 0.32);
            border-radius: 8px;
            height: 100%;
            @media all and (max-width: $bigDesktop) {
                padding: 20px;
            }
            @media all and (max-width: $normalDesktop) {
                padding: 15px 20px;
            }
            @media all and (max-width: $tablet) and (min-width: $realTablet) {
                padding: 12px 14px;
            }
            @media all and (max-width: $smallTablet) {
                position: relative;
                border-radius: 8px;
                background: rgba(41, 52, 139, 0.16);
                padding: 0 8px;
                height: unset;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 8px;
                    padding: 1px;
                    background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
                    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                    -webkit-mask-composite: xor;
                    mask-composite: exclude;
                }
            }
            .promocodeLeftList {
                list-style-type: none;
                margin: 0;
                padding: 0;
                position: relative;
                z-index: 1;
                > li {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    padding-top: 44px;
                    padding-bottom: 44px;
                    @media all and (max-width: $normalDesktop) {
                        padding-top: 25px;
                        padding-bottom: 25px;
                    }
                    &:first-child {
                        padding-top: 20px;
                        border-bottom: 1px solid rgba(182, 193, 255, 0.12);
                    }
                }

                .sponsorsList {
                    margin-top: 28px;
                    list-style-type: decimal;
                    list-style-position: inside;
                    padding: 0;
                    width: 100%;
                    max-height: 250px;
                    height: 100%;
                    overflow: auto;
                    padding-right: 33px;
                    @media all and (max-width: $tablet) and (min-width: $realTablet) {
                        max-height: 690px;
                        padding-right: 0;
                    }
                    &::-webkit-scrollbar {
                        width: 8px;
                        border-radius: 10px;
                        @media all and (max-width: $tablet) {
                            width: 4px;
                        }
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 10px;
                        background: linear-gradient(
                            229.09deg,
                            rgba(173, 179, 189, 0.24) 3.76%,
                            rgba(104, 115, 117, 0.24) 115.42%
                        );
                    }
                    &::-webkit-scrollbar-thumb {
                        background: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
                        border-radius: 10px;
                    }
                    li {
                        display: list-item;
                        @include fontRem(16, 500);
                        line-height: 44px;
                        color: $titleColor;
                        padding-bottom: 8px;
                        margin-bottom: 8px;
                        width: 100%;
                        @media all and (max-width: $tablet) and (min-width: $realTablet) {
                            @include fontRem(14, 500);
                        }
                        &:not(:last-child) {
                            border-bottom: 1px solid rgba(182, 193, 255, 0.08);
                        }
                        p {
                            padding-left: 12px;
                            display: inline-flex;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .promocodeForm {
                [class*="formItem"] {
                    margin-bottom: 24px;
                }
                .promocodeBtn {
                    width: auto;
                    min-width: 164px;
                    padding: 13px 30px;
                }
            }
        }
        .poromocodeMobileTopView {
            background: rgba(41, 52, 139, 0.16);
            border-radius: 8px;
            position: relative;
            margin-bottom: 8px;
            padding: 20px 8px 24px;
            @media all and (max-width: $tablet) and (min-width: $realTablet) {
                background-color: transparent;
            }
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 8px;
                padding: 1px;
                background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                @media all and (max-width: $tablet) and (min-width: $realTablet) {
                    content: none;
                }
            }
            .promocodeMobilTopHeader {
                display: flex;
                align-items: flex-start;
                position: relative;
                z-index: 1;
                margin-bottom: 16px;
                padding-bottom: 16px;
                border-bottom: 1px solid rgba(182, 193, 255, 0.16);
                .reffrealLinkInfo {
                    .refferalLink {
                        @include textShorten(1);
                        max-width: 227px;
                    }
                }
            }
            .reffrealLinkInfoLogo {
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
            .promocodeForm {
                [class*="formItem"] {
                    margin-bottom: 24px;
                }
                .promocodeBtn {
                    width: auto;
                    height: 40px;
                }
            }
        }
    }
}
