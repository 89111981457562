@import "/src/styles/vars";
@import "/src/styles/mixins";

.footer {
    margin-top: auto;
    padding: 0 0 46px;
    @media all and (max-width: $smallTablet) {
        padding-bottom: 16px;
    }

    background: linear-gradient(180deg, rgba(18, 13, 31, 0.32) 0%, #120d1f 100%);
    [class="container"] {
        max-width: 100%;
        padding-left: 80px;
        padding-right: 80px;
        @media all and (max-width: $normalDesktop) {
            padding-left: 56px;
            padding-right: 56px;
        }
        @media all and (max-width: $smallTablet) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .footerTop {
        padding-top: 37px;
        @include flex-center-column;
        border-top: 4px solid rgba(182, 193, 255, 0.12);
        .footerLogo {
            img {
                width: 169px;
            }
            // margin-bottom: 68px;
        }
        .footerSoccial {
            .footerSocialList {
                @include flex-center-vert;
                list-style-type: none;
                li {
                    &:not(:first-child) {
                        margin-left: 24px;
                    }
                    span {
                        background: rgba(41, 52, 139, 0.32);
                        cursor: pointer;
                        @include flex-center;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        padding: 8px;
                        svg {
                            width: 16px;
                        }
                    }
                }
            }
        }
        .footerContact {
            @include flex-center-column;
            .contactItem {
                margin-top: 50px;

                @include flex-center-vert;
                color: $white;
                text-decoration: none;
                span {
                    margin-right: 24px;
                }
            }
            .designerInfo {
                .desingnerInfoList {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    @include flex-center-vert;
                    @media all and (max-width: $tablet) {
                        margin-top: 50px;
                    } 
                    @media all and (min-width: $tablet) {
                        transform: translateY(78px);
                    }
                    li {
                        @include fontRem(14, 500);
                        line-height: 17px;
                        color: $white;
                        margin-left: 12px;
                        &:first-child {
                            margin-left: 0;
                            margin-right: 4px;
                            span {
                                font-weight: 400;
                            }
                        }
                        a {
                            background: rgba(41, 52, 139, 0.32);
                            cursor: pointer;
                            @include flex-center;
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            padding: 8px;
                            svg {
                                width: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    
}

.footerBottom {
    margin-top: 52px;
    display: flex;
    flex-wrap: wrap;
    @media all and (max-width: $smallTablet) {
        flex-direction: column-reverse;
    }
    .copyRight {
        @include fontRem(18, 400);
        line-height: 21px;
        color: $white;
        @include flex-center-vert;

        @media all and (max-width: $normalDesktop) {
            @include fontRem(14, 400);
            line-height: 17px;
        }
        @media all and (max-width: $smallTablet) {
            margin-top: 24px;
            justify-content: center;
        }
        span {
            margin-right: 7px;
        }
    }
    .footerMenu {
        margin-left: auto;
        @media all and (max-width: $smallTablet) {
            margin-right: auto;
        }
        .footerMenuLink {
            cursor: pointer;
            @include fontRem(18, 400);
            line-height: 21px;
            text-decoration: none;
            color: $white;
            @media all and (max-width: $normalDesktop) {
                @include fontRem(14, 400);
                line-height: 17px;
            }
            &:not(:first-child) {
                margin-left: 64px;
                @media all and (max-width: $normalDesktop) {
                    @include fontRem(14, 400);
                    margin-left: 40px;
                }
                @media all and (max-width: $smallTablet) {
                    margin-left: 20px;
                }
            }
        }
    }
}