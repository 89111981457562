@import "/src/styles/vars";
@import "/src/styles/mixins";

.forecastSection {
    padding: 28px 24px;
    background: rgba(41, 52, 139, 0.16);
    border-radius: 8px;
    position: relative;
    @media all and (max-width: $tablet) {
        padding: 36px 8px;
    }
    @media all and (max-width: $smallTablet) {
        padding: 0;
        background-color: transparent;
    }
    .forecastHeader {
        max-width: 665px;
        margin: 0 auto;
        @media all and (max-width: $smallTablet) {
            max-width: 100%;
            margin-top: 54px;
        }

        .forecasstChangers {
            position: relative;
            @include flexRow(0, 3, center);
            list-style-type: none;
            justify-content: center;
            padding: 0 0 12px;         
            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                background: #18AAFC;
                border-radius: 10px;
                width: 33%;
                height: 4px;
            } 
            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                background: rgba(41, 52, 139, 0.32);
                border-radius: 10px;
                width: 100%;
                height: 4px;
            } 
             .disabled{
                    cursor: no-drop !important;
                }
            li {
                @media all and (max-width: $smallTablet) {
                    flex-grow: 1 !important;
                    flex-shrink: 0 !important;
                    flex-basis: auto !important;
                    max-width: 100% !important;
                }
                button {
                    position: relative;
                    @include buttonNulled;
                    width: 100%;
                    position: relative;
                    @include fontRem(18, 400);
                    line-height: 18px;
                    color: rgba(251, 252, 255, 0.56);
                    
                    @media all and (max-width: $smallTablet) {
                        @include fontRem(14, 400);
                        line-height: 17px;
                    }
                    &.active {
                        color: $titleColor;h
                        &::after {
                            content: "";
                            position: absolute;
                            bottom: -13px;
                            left: 0;
                            width: 100%;
                            height: 4px;
                            background: #18aafc;
                            border-radius: 10px;
                        }
                    }
                    .readyCount {  
                        position: absolute;
                        top: -12px;
                        right: 0;
                        width: 30px;
                        height: 24px;
                        background: rgba(33, 90, 231, 0.12);
                        border-radius: 8px;
                        padding: 2px 10px;
                        @include fontRem(16, 500);
                        color: $titleColor;
                        @media all and (max-width: $smallTablet) {
                            top: -30px;
                            @include fontRem(12, 500);
                        }
                    }
                }
            }
        }
    }
    .forecastBody {
        margin-top: 33px;
        @media all and (max-width: $smallTablet) {
            margin-top: 24px;
        }
        .waittimeInfo {
            margin-bottom: 22px;
            @include fontRem(20, 400);
            color: rgba(251, 252, 255, 0.64);
            span {
                font-weight: 600;
                color: $white;
            }
            @media all and (max-width: $smallTablet) {
                margin-bottom: 10px;
                @include fontRem(12, 400);
                line-height: 44px;
            }
        }
        .foreacstBodyItem {
            padding-bottom: 16px;
            margin-bottom: 16px;
            @media all and (max-width: $smallTablet) {
                position: relative;
                background: rgba(41, 52, 139, 0.16);
                border-radius: 8px;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 8px;
                    padding: 1px;
                    background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
                    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                    -webkit-mask-composite: xor;
                    mask-composite: exclude;
                }
            }
            &:not(:last-child) {
                border-bottom: 2px solid rgba(182, 193, 255, 0.16);
                @media all and (max-width: $smallTablet) {
                    border-bottom: 0;
                }
            }
            .forecastBodyItemContent {
                background: #141528;
                border-radius: 8px;
                padding: 32px 16px 18px 44px;
                position: relative;
                z-index: 1;
                @media all and (max-width: $tablet) {
                    padding: 20px 10px;
                }
                @media all and (max-width: $smallTablet) {
                    background-color: transparent;
                    padding: 30px 10px 74px;
                }
                .bodyItem {
                    .bodyRow {
                        @include flexRow(20, 2);
                        @media all and (max-width: $smallTablet) {
                            @include flexRow(20, 1);
                        }
                        .customCol {
                            padding-left: 20px;
                            padding-right: 20px;
                            &.customColSmall {
                                flex: 0 0 40%;
                                max-width: 40%;
                                @media all and (max-width: $tablet) {
                                    flex: 0 0 35%;
                                    max-width: 35%;
                                }
                                @media all and (max-width: $smallTablet) {
                                    flex: 0 0 100%;
                                    max-width: 100%;
                                }
                            }
                            &.customColBig {
                                flex: 0 0 60%;
                                max-width: 60%;
                                @media all and (max-width: $tablet) {
                                    flex: 0 0 65%;
                                    max-width: 65%;
                                    padding-left: 0;
                                }
                                @media all and (max-width: $smallTablet) {
                                    flex: 0 0 100%;
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                    .bodyItemLeft {
                        padding-right: 20px;
                        border-right: 2px solid rgba(41, 52, 139, 0.44);
                        @media all and (max-width: $smallTablet) {
                            padding-right: 0;
                            border-right: 0;
                            padding-left: 6px;
                            border-bottom: 2px solid rgba(41, 52, 139, 0.44);
                        }
                        .leftItem {
                            &:not(:last-child) {
                                margin-bottom: 32px;
                                @media all and (max-width: $smallTablet) {
                                    margin-bottom: 24px;
                                }
                            }
                            svg {
                                margin-right: 24px;
                                @media all and (max-width: $tablet) {
                                    width: 32px;
                                    height: 32px;
                                    margin-right: 16px;
                                }
                            }
                            p {
                                @include flex-center-vert;
                                flex-wrap: wrap;
                                @include fontRem(18, 400);
                                line-height: 44px;
                                margin-bottom: 0;
                                color: $titleColor;
                                @media all and (max-width: $tablet) {
                                    @include fontRem(12, 400);
                                    line-height: 30px;
                                }
                                span {
                                    font-weight: 600;
                                    color: $white;
                                }
                                p {
                                    margin-top: -15px;
                                    flex: 0 0 100%;
                                    padding-left: 64px;
                                    @media all and (max-width: $tablet) {
                                        padding-left: 48px;
                                    }

                                    small {
                                        color: rgba(251, 252, 255, 0.72);
                                    }
                                }
                            }
                            .orderDate{
                                margin-left: 5px
                            }
                        }
                        .leftItemBtns {
                            @include flex-center-vert;
                            @media all and (max-width: $smallTablet) {
                                position: absolute;
                                bottom: 0;
                                right: 10px;
                            }
                            .links {
                                color: $blue;
                                cursor: pointer;
                                display: inline-block;
                                @include fontRem(18, 500);
                                line-height: 22px;
                                transition: 0.3s;
                                text-decoration: none;
                                @media all and (max-width: $smallTablet) {
                                    @include fontRem(14, 500);
                                }
                                &:hover {
                                    text-decoration: underline;
                                }
                                &:active {
                                    text-decoration: underline;
                                    background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    background-image: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
                                }
                                &:not(:last-child) {
                                    margin-right: 22px;
                                    padding-right: 22px;
                                    border-right: 1px solid rgba(41, 52, 139, 0.44);
                                    @media all and (max-width: $smallTablet) {
                                        margin-right: 34px;
                                        padding-right: 34px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .bodyItemRight {
            padding-top: 20px;
            .stepBarItem {
                background: rgba(41, 52, 139, 0.24);
                border-radius: 8px;
                padding: 18px 48px;
                margin-right: 24px;
                @media all and (max-width: $tablet) {
                    margin-right: 7px;
                    padding: 20px 7px;
                }
                @media all and (max-width: $smallTablet) {
                    margin-right: 0;
                    padding: 18px 12px;
                    max-width: 100%;
                    margin-left: 20px;
                }
                .stepBarRow {
                    position: relative;
                    padding-bottom: 22px;
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        width: 90%;
                        height: 6px;
                        left: 50%;
                        transform: translateX(-50%);
                        background: rgba(41, 52, 139, 0.44);
                        border-radius: 10px;
                        background: linear-gradient(90deg, #7b43e4 50%, rgba(41, 52, 139, 0.44) 50%);
                        @media all and (min-width: $normalDesktop) {
                            width: 92%;
                        }
                        @media all and (max-width: $smallTablet) {
                            width: 100%;
                        }
                    }
                    @include flex-center-vert;
                    .stepBarRowItem {
                        flex: 0 0 25%;
                        &:first-child {
                            .stepBarInner {
                                p {
                                    text-align: left;
                                    @media all and (max-width: $smallTablet) {
                                        &::after {
                                            @media all and (max-width: $smallTablet) {
                                                left: -10%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:last-child {
                            .stepBarInner {
                                p {
                                    text-align: right;
                                    &::after {
                                        left: auto;
                                        right: 10%;
                                        transform: translateX(-50%);
                                        @media all and (max-width: $smallTablet) {
                                            right: -10%;
                                        }
                                    }
                                }
                            }
                        }
                        .stepBarInner {
                            p {
                                position: relative;
                                @include fontRem(16, 600);
                                line-height: 20px;
                                color: $titleColor;
                                @media all and (max-width: $smallTablet) {
                                    @include fontRem(10, 400);
                                    line-height: 20px;
                                    color: rgba(251, 252, 255, 0.64);
                                }
                                &::after {
                                    content: "";
                                    position: absolute;
                                    top: calc(100% + 13px);
                                    width: 11px;
                                    height: 11px;
                                    left: 10%;
                                    transform: translateX(50%);
                                    border-radius: 50%;
                                    background-color: #7b43e4;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                    .stepBarRowItemBig {
                        flex: 0 0 50%;
                        .stepBarInner {
                            p {
                                @include fontRem(16, 600);
                                line-height: 20px;
                                color: $titleColor;
                                position: relative;
                                text-align: center;
                                @media all and (max-width: $smallTablet) {
                                    @include fontRem(10, 400);
                                }
                                &::after {
                                    content: "";
                                    position: absolute;
                                    top: calc(100% + 10px);
                                    width: 17px;
                                    height: 17px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    border-radius: 50%;
                                    background: #7b43e4;
                                    box-shadow: 4px 8px 24px rgba(19, 20, 38, 0.16);
                                    filter: blur(1px);
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
                &.stepBarItemFull {
                    .stepBarRow {
                        &::after {
                            // background: #28ccd2;
                        }
                        .stepBarInner {
                            p {
                                &::after {
                                    // background: #28ccd2;
                                }
                            }
                        }
                        .stepBarRowItemBig {
                            .stepBarInner {
                                p {
                                    &::after {
                                        width: 15px;
                                        height: 15px;
                                        box-shadow: unset;
                                        filter: unset;
                                        top: calc(100% + 11px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .forecastBtns {
                margin-top: 37px;
                @include flexRow(8, 3, center);
                margin-left: auto;
                justify-content: flex-end;
                @media all and (max-width: $tablet) {
                    @include flexRow(4, 3, center);
                }
                @media all and (max-width: $smallTablet) {
                    @include flexRow(8, 1, center);
                    margin-left: auto;
                    margin-right: auto;
                    justify-content: center;
                    flex-direction: column;
                }
                .btnParent {
                    position: relative;
                    padding-left: 8px;
                    padding-right: 8px;
                    @media all and (max-width: $tablet) {
                        padding-left: 4px;
                        padding-right: 4px;
                    }
                    &:not(:first-child) {
                        button {
                            background-image: unset;
                            position: relative;
                            border-radius: 8px;
                            @media all and (max-width: $smallTablet) {
                                margin-top: 24px;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                border-radius: 5px;
                                padding: 1px;
                                background: linear-gradient(rgba(24, 117, 252, 1), rgba(0, 221, 251, 1));
                                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                                mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                                -webkit-mask-composite: xor;
                                mask-composite: exclude;
                            }
                            &:disabled {
                                &::before {
                                    background: linear-gradient(rgba(173, 179, 189, 0.32), rgba(173, 179, 189, 0.32));
                                }
                            }
                        }
                    }
                    button {
                        border-radius: 8px;
                        width: 164px;
                        padding: 12px 5px;
                        @include fontRem(16, 600);
                        line-height: 20px;
                        color: $titleColor;
                        @media all and (max-width: $desktop) {
                            width: 100%;
                            @include fontRem(14, 600);
                            padding: 12px 10px;
                        }
                        @media all and (max-width: $tablet) {
                            width: 130px;
                            padding: 12px 0;
                        }
                        @media all and (max-width: $smallTablet) {
                            min-width: 212px;
                        }
                        &:disabled {
                            cursor: not-allowed;
                            color: rgba(251, 252, 255, 0.32);
                        }
                    }
                }
            }
        }
    }
}
