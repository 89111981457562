@import "/src/styles/vars";
@import "/src/styles/mixins";

.diclaimerWrapper {
    display: flex;
    align-items: center;
    .diclaimerLabel {
        padding-left: 40px;
        text-align: center;
        color: rgba(227, 230, 234, 0.32);
        @include fontRem(18, 400);
        line-height: 22px;
        @media all and (max-width: $tablet) {
            @include fontRem(12, 400);
            text-align: left;
            line-height: 1;
        }
        a {
            cursor: pointer;
            color: $blue;
            margin-left: 10px;
            display: inline-block;
            @include fontRem(18, 500);
            line-height: 22px;
            transition: 0.3s;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            &:active {
                text-decoration: underline;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                background-image: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
            }
            @media all and (max-width: $tablet) {
                @include fontRem(14, 400);
                text-align: left;
                margin-left: 0;
            }
        }
    }
}
