@use "sass:math";
@import "/src/styles/vars";
@import "/src/styles/mixins";
@import "../../../Assets/styles/locale.scss";

.col_33 {
  @media all and (max-width: $tablet) {
    &:not(:last-child) {
      .whyWeItem {
        margin-bottom: 72.5px;
      }
    }
  }
}
.whyWeItem {
  position: relative;
  background: rgba(41, 52, 139, 0.24);
  backdrop-filter: blur(16px);
  border-radius: 16px;
  text-align: center;
  color: $white;
  height: 340px;
  padding: 0 14px;
  padding-bottom: 56px;
  @media all and (max-width: $normalDesktop) {
    height: 312px;
  }
  @media all and (max-width: $tablet) {
    height: 240px;
  }
  @media all and (max-width: $smallTablet) {
    height: 312px;
  }
  @media all and (max-width: $mobile) {
    height: 192px;
  }
  @supports (
    mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(
      #fff 0 0,
    )
  ) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px;
      padding: 1px;
      background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
  .whyWeIcon {
    @include flex-center;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-50%);
    svg {
      border-radius: 50%;
      @media all and (max-width: $normalDesktop) {
        width: 68px;
        height: 68px;
      }
    }
  }
  .wheyWeTitle {
    @include fontRem(28, 700);
    line-height: 34px;
    color: $titleColor;
    @media all and (max-width: $normalDesktop) {
      @include fontRem(24, 700);
      margin-top: -6px;
    }
    @media all and (max-width: $tablet) {
      @include fontRem(24, 700);
      line-height: 30px;
      margin-top: -25px;
      letter-spacing: 0.055em;
      text-align: center;
    }
    @media all and (max-width: $smallTablet) {
      @include fontRem(20, 700);
      line-height: 30px;
      margin-top: -25px;
      letter-spacing: 0.055em;
      text-align: center;
    }
  }
  .wheyWeText {
    margin-top: 40px;
    max-width: 303px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @include fontRem(18, 400);
    line-height: 24px;
    color: $titleColor;
    @media all and (max-width: $normalDesktop) {
      @include fontRem(16, 400);
      margin-top: 24px;
      line-height: 22px;
    }
    @media all and (max-width: $tablet) {
      @include fontRem(14, 400);
      margin-top: 16px;
      line-height: 19px;
      max-width: 260px;
    }
  }
}
