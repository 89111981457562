// Colors


$white: #ffffff;
$black: #000;
$titleColor: #FBFCFF;
$blue: #215AE7;

// breakpoints

$bigDesktop: 1919.98px;
$normalDesktop: 1600px;
$desktop: 1440px;
$tablet: 1279.98px;
$smallTablet: 767px;
$realTablet: 768px;
$mobile: 576.98px;