@import "/src/styles/mixins";
@import "/src/styles/vars";

.analyticPage {
    .analyticSeciton {
        background-image: url("../../../Assets/images/analytic-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;
        width: 100%;
        padding-top: 64px;
        padding-bottom: 100px !important;
        @media all and (max-width: $tablet) {
            padding: 64px 10px !important;
        }
        .goBackItem {
            @include flex-center-vert;
            margin-bottom: 24px;
            cursor: pointer;
            p {
                margin-left: 8px;
                color: $white;
                @include fontRem(18, 500);
                line-height: 22px;
                letter-spacing: 0.055em;
                @media all and (max-width: $tablet) {
                    @include fontRem(14, 400);
                }
            }
        }
        .analyticRow {
            @include flexRow(32, 2, center);
            @media all and (max-width: $tablet) {
                @include flexRow(12, 1, center);
                flex-direction: column-reverse;
            }
            .analyticLeftColContent {
                svg {
                    max-width: 100%;
                    height: auto;
                }
            }
            .rightInner {
                background: rgba(41, 52, 139, 0.24);
                padding: 16px 0;
                @media all and (max-width: $tablet) {
                    border-radius: 8px;
                }
            }
            .rightItem {
                height: 100%;
                max-height: 600px;
                overflow: auto;
                border-radius: 8px;
                padding: 62px;
                @media all and (max-width: $tablet) {
                    padding: 32px 8px;
                }
                @media all and (max-width: $smallTablet) {
                    max-height: unset;
                }
                &::-webkit-scrollbar {
                    width: 8px;
                    border-radius: 10px;
                    @media all and (max-width: $tablet) {
                        width: 4px;
                    }
                }
                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background: linear-gradient(
                        229.09deg,
                        rgba(173, 179, 189, 0.24) 3.76%,
                        rgba(104, 115, 117, 0.24) 115.42%
                    );
                }
                &::-webkit-scrollbar-thumb {
                    background: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
                    border-radius: 10px;
                }
                .itemTitle {
                    @include fontRem(44, 700);
                    line-height: 54px;
                    letter-spacing: 0.055em;
                    color: $titleColor;
                    max-width: unset;
                    text-align: left;
                    margin-left: 0;
                    margin-bottom: 32px;
                    @media all and (max-width: $normalDesktop) {
                        @include fontRem(32, 700);
                        line-height: 40px;
                        margin-bottom: 24px;
                        max-width: 752px;
                    }
                    @media all and (max-width: $tablet) {
                        @include fontRem(24, 700);
                        line-height: 30px;
                        margin-bottom: 16px;
                        max-width: 302px;
                    }
                }
                .itemText {
                    @include fontRem(18, 400);
                    line-height: 27px;
                    color: $white;
                    @media all and (max-width: $normalDesktop) {
                        @include fontRem(16, 400);
                        line-height: 22px;
                    }
                    @media all and (max-width: $tablet) {
                        @include fontRem(14, 400);
                    }
                }
            }
        }
    }
}
