@import "/src/styles/vars";
@import "/src/styles/mixins";

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    background: rgba(41, 52, 139, 0.24);
    backdrop-filter: blur(16px);
}

.dashboardPage {
    width: 100%;
    min-height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: linear-gradient(to right bottom, #151a2c, #120d20);
    @media all and (max-width: $smallTablet) {
        padding: 0 16px;
        [class="container"] {
            padding: 0;
        }
    } 
}
.dashoardRight {
    margin-left: 380px;
    width: calc(100% - 380px);
    padding-top: 11px;
    padding-right: 80px;
    padding-bottom: 32px;
    @media all and (max-width: $normalDesktop) {
        padding-right: 56px;
        width: calc(100% - 320px);
        margin-left: 320px;
    }
    @media all and (max-width: $tablet) {
        padding-right: 20px;
        width: calc(100% - 92px);
        margin-left: 92px;
    }
    @media all and (max-width: $smallTablet) {
        margin-left: 0;
        padding-right: 0;
        width: 100%;
    } 
}
.dashboardSideOpen {
    @include buttonNulled;
    margin-left: 5px;
    margin-bottom: 15px;
    position: relative;
    z-index: 3;
}
.dashboardFooter {
    height: 50px;
    margin-left: 380px;
    width: calc(100% - 460px);
    z-index: 1;
    border-top: 4px solid rgba(182, 193, 255, 0.12);
    @include flex-center-vert;
    [class*="footerBottom"] {
        margin-top: 0;
        width: 100%;
    }
    @media all and (max-width: $normalDesktop) {
        margin-left: 320px;
        width: calc(100% - 376px);
    }
    @media all and (max-width: $tablet) {
        margin-left: 92px;
        width: calc(100% - 112px);
    }
    @media all and (max-width: $smallTablet) {
        margin: 0;
        width: 100%;
        padding: 20px 0 8px;
        height: unset;
        padding-top: 20px;
    }
}
