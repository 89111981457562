@import "/src/styles/vars";
@import "/src/styles/mixins";

.aboutPage {
    width: 100%;
    min-height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.aboutUsSection {
    padding: 100px 0;
    @media all and (max-width: $tablet) {
        padding: 70px 0;
    }
    .aboutUsRow {
        @include flexRow(50, 2, flex-start);
        @media all and (max-width: $tablet) {
            @include flexRow(50, 1, flex-start);
        }
        .aboutUsInfo {
            margin-top: 60px;
            @media all and (max-width: $tablet) {
                margin-top: 0;
            }
            [class="title"] {
                margin-left: 0;
                text-align: left;
                @media all and (max-width: $tablet) {
                    @include fontRem(44, 700);
                    line-height: 53px;
                }
                @media all and (max-width: $mobile) {
                    @include fontRem(24, 700);
                    line-height: 30px;
                    margin-bottom: 16px;
                }
            }
            [class="subTitle"] {
                text-align: left;
                max-width: 520px;
                margin-bottom: 40px;
                margin-left: 0;
                margin-right: 0;
                @include fontRem(16, 400);
                @media all and (max-width: $mobile) {
                    @include fontRem(14, 400);
                    line-height: 20px;
                }
            }
            .aboutUsContactContainer {
                .aboutUsContact {
                    .contactItem {
                        margin-top: 34px;
                        @include flex-center-vert;
                        color: $white;
                        text-decoration: none;
                        span {
                            margin-right: 16px;
                        }
                    }
                }
                .aboutSocialList {
                    margin-top: 32px;
                    @include flex-center-vert;
                    list-style-type: none;
                    li {
                        &:not(:first-child) {
                            margin-left: 24px;
                        }
                        span {
                            background: rgba(41, 52, 139, 0.32);
                            cursor: pointer;
                            @include flex-center;
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            padding: 8px;
                            svg {
                                width: 16px;
                            }
                        }
                    }
                }
            }
        }
        .timelineContainer {
            position: relative;
            width: 100%;
            @media all and (max-width: $smallTablet) {
                margin-top: 60px;
            }
            &::before {
                content: "";
                position: absolute;
                left: 50%;
                width: 2px;
                height: 100%;
                background: #c5c5c5;
                background-image: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
                @media all and (max-width: $mobile) {
                    content: none;
                } 
            }
            ul {
                padding: 0;
                margin: 0;
                li {
                    list-style: none;
                    position: relative;
                    width: 50%;
                    @media all and (max-width: $mobile) {
                        width: 100%;
                    }

                    &:nth-child(odd) {
                        float: left;
                        text-align: right;
                        clear: both;
                        padding: 0 64px 0 0;
                        margin-bottom: 100px;
                        @media all and (max-width: $smallTablet) {
                            margin-bottom: 50px;
                        }
                        @media all and (max-width: $mobile) {
                            float: right;
                            padding: 0 0 0 10px;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            width: 64px;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            height: 2px;
                            border: 1px dashed $blue;
                            @media all and (max-width: $mobile) {
                                content: none;
                            }
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                            right: -6px;
                            background: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
                            border-radius: 50%;
                            z-index: 1;
                            @media all and (max-width: $mobile) {
                                left: -4px;
                                content: none;
                            }
                        }
                        .timelineDate {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: -165px;
                            border-radius: 18px;
                            @media all and (max-width: $mobile) {
                                left: 0;
                                right: auto;
                            }
                        }
                    }

                    &:nth-child(even) {
                        float: right;
                        text-align: left;
                        clear: both;
                        padding: 0 0 0 64px;
                        margin-bottom: 100px;
                        @media all and (max-width: $smallTablet) {
                            margin-bottom: 50px;
                        }
                        @media all and (max-width: $mobile) {
                            padding: 0 0 0 10px;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            width: 64px;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            height: 2px;
                            border: 1px dashed $blue;
                            @media all and (max-width: $mobile) {
                                content: none;
                            }
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                            left: -4px;
                            background: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
                            border-radius: 50%;
                            z-index: 1;
                            @media all and (max-width: $mobile) {
                                content: none;
                            }
                        }
                        .timelineDate {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: -165px;
                            @media all and (max-width: $mobile) {
                                left: 0;
                                right: auto;
                            }
                        }
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                        .timelineContent {
                            &::after {
                                height: 100%;
                            }
                        }
                    }
                    .timelineContent {
                        z-index: 2;
                        background: rgba(33, 90, 231, 0.2);
                        box-shadow: 0px 0px 32px rgba(33, 90, 231, 0.24);
                        backdrop-filter: blur(16px);
                        border-radius: 16px;
                        padding: 34px 17px;
                        @media all and (max-width: $mobile) {
                            position: relative;
                            width: 68.5%;
                            margin-left: auto;
                            .mobileDot {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: -24px;
                                width: 14px;
                                height: 14px;
                                border-radius: 50%;
                                background: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
                                z-index: 2;
                            }
                            .mobileLine {
                                position: absolute;
                                width: 24px;
                                left: -24px;
                                top: 50%;
                                transform: translateY(-50%);
                                height: 2px;
                                border: 1px dashed $blue;
                            }
                        }
                        @supports (
                            mask: linear-gradient(#fff 0 0) content-box,
                            linear-gradient(
                                #fff 0 0,
                            )
                        ) {
                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                border-radius: 16px;
                                padding: 1px;
                                background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
                                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                                mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                                -webkit-mask-composite: xor;
                                mask-composite: exclude;
                            }
                        }
                        .timelineTitle {
                            padding: 0;
                            margin: 0;
                            font-weight: 600;
                            text-align: center;
                            text-transform: uppercase;
                            @include fontRem(20, 700);
                            line-height: 28px;
                            color: $white;
                            @media all and (max-width: $smallTablet) {
                                @include fontRem(18, 700);
                                line-height: 26px;
                            }
                        }
                        .timelineText {
                            position: relative;
                            z-index: 1;
                            padding: 0;
                            text-align: center;
                            @include fontRem(18, 400);
                            color: $white;
                            @media all and (max-width: $smallTablet) {
                                @include fontRem(14, 400);
                                line-height: 20px;
                            }
                        }
                        &::after {
                            content: none;
                            position: absolute;
                            left: -18px;
                            width: 2px;
                            height: calc(100% + 51px);
                            top: 0;
                            background-image: linear-gradient(229.09deg, #1875fc 3.76%, #1875fc 115.42%);
                            z-index: 1;
                            @media all and (max-width: $mobile) {
                                content: "";
                            } 
                        }
                    }
                    .timelineDate {
                        margin: 0;
                        padding: 0;
                        p {
                            @include fontRem(20, 700);
                            line-height: 28px;
                            color: $white;
                            @media all and (max-width: $mobile) {
                                @include fontRem(14, 600);
                                line-height: 18px;
                            }
                        }
                    }
                }
                .bothClenar {
                    clear: both;
                }
            }
        }
        .tabletTimeline {
            margin-top: 100px;
            padding-left: 10px;
            padding-right: 10px;
            .tabletItemsRow {
                @include flexRow(12, 3, flex-start);
            }
            .tabletItemsTop {
                padding-bottom: 40px;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-image: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
                }
                .tabletItemsRow {
                    .tabletItem {
                        position: relative;
                        &::after {
                            content: "";
                            position: absolute;
                            top: 100%;
                            height: 40px;
                            width: 1px;
                            left: 50%;
                            transform: translateX(-50%);
                            border-left: 1px dashed $blue;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            top: calc(100% + 33px);
                            left: 50%;
                            transform: translateX(-50%);
                            width: 14px;
                            height: 14px;
                            border-radius: 50%;
                            background: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
                            z-index: 2;
                        }
                    }
                    .tableItemBlock {
                        position: relative;
                        background: rgba(33, 90, 231, 0.2);
                        box-shadow: 0px 0px 32px rgba(33, 90, 231, 0.24);
                        backdrop-filter: blur(16px);
                        border-radius: 16px;
                        padding: 25px 5px;
                        text-align: center;
                        min-height: 130px;
                        @supports (
                            mask: linear-gradient(#fff 0 0) content-box,
                            linear-gradient(
                                #fff 0 0,
                            )
                        ) {
                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                border-radius: 16px;
                                padding: 1px;
                                background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
                                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                                mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                                -webkit-mask-composite: xor;
                                mask-composite: exclude;
                            }
                        }
                        .tableItemBlockTitle {
                            @include fontRem(14, 700);
                            line-height: 20px;
                            letter-spacing: 1px;
                            text-transform: uppercase;
                            color: $white;
                        }
                        .tableItemBlockText {
                            @include fontRem(14, 400);
                            line-height: 20px;
                            color: $titleColor;
                        }
                    }
                }
            }
            .tabletItemsBottom {
                margin-top: 10px;
                text-align: center;
                .tableItemBlockText {
                    @include fontRem(14, 600);
                    line-height: 20px;
                    color: $titleColor;
                    text-transform: uppercase;
                }
            }
        }
    }
}
