@import "/src/styles/vars";
@import "/src/styles/mixins";
.confirmModal{
    display: flex;
    flex-direction: column;
    align-items: center;
}
// .modalTitle{
//     max-width: 502px;
//     text-align: center;
//     @media all and (max-width: $smallTablet) {
//         @include fontRem(20, 700);
//         max-width: 502px
//     }
// }
.modalTitle {
    text-align: center;
    @include fontRem(28, 700);
    line-height: 36px;
    color: $titleColor;
    
    @media all and (max-width: $smallTablet) {
        @include fontRem(20, 700);
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        line-height: 30px;
    }
}
.modalSubtitle{
    text-align: center;
    margin-top: 15px;
    @include fontRem(16, 700);
    line-height: 36px;
    color: $titleColor;
    
    @media all and (max-width: $smallTablet) {
        @include fontRem(14, 700);
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        line-height: 30px;
    }
}
.detailsList {
    margin-top: 30px;
    padding-left: 16px;
    padding-right: 16px;
    @media all and (max-width: $smallTablet) {
        padding-left: 0;
        padding-right: 0;
    }
    .modalBtn {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        gap: 16px;
        button {
    
            width: auto;
            min-width: 164px;
            padding:0  ;
            @media all and (max-width: $smallTablet) {
                width: 100%;
                height: 48px;
            }
        }
    }
}
