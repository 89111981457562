@import "/src/styles/vars";
@import "/src/styles/mixins";

.loginPage {
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding-bottom: 84px;
    background-image: url("../../../Assets/images/login-bg.png"),
        linear-gradient(170.79deg, #151a2c 0.37%, #120d20 93.02%);

    .loginHeader {
        background: rgba(20, 21, 39, 0.24);
        backdrop-filter: blur(12px);
        min-height: 94px;
        @include flex-center-vert;
        padding-left: 80px;
        padding-right: 80px;
        margin-bottom: 84px;
        @media all and (max-width: $normalDesktop) {
            padding-left: 56px;
            padding-right: 56px;
        }
        div {
            cursor: pointer;
            transition: 1s ease-out;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    .loginSection {
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        .loginForm {
            margin: auto;
            max-width: 660px;
            width: 100%;
            background: rgba(41, 52, 139, 0.24);
            backdrop-filter: blur(16px);
            overflow: hidden;
            border-radius: 8px;
            padding: 56px 64px 26px;
            @media all and (max-width: $tablet) {
                padding: 40px 37px 24px;
            } 
            &.activeLoader {
                backdrop-filter: unset;
            }
        }
        .loginTitle {
            text-align: center;
            margin-bottom: 64px;
            @media all and (max-width: $tablet) {
                margin-bottom: 48px;
            }
        }

        .forgotBtn {
            margin-top: 24px;
            span {
                color: $blue;
                cursor: pointer;
                display: inline-block;
                @include fontRem(18, 500);
                line-height: 22px;
                transition: 0.3s;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
                &:active {
                    text-decoration: underline;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-image: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
                }
            }
        }
        .loginBtn {
            margin-top: 72px;
            @media all and (max-width: $tablet) {
                height: 48px;
                margin-top: 48px;
            } 
        }
        .dontHaveAccount {
            margin-top: 72px;
            @media all and (max-width: $tablet) {
                margin-top: 32px;
            } 
            .linked {
                cursor: pointer;
                color: $blue;
                margin-left: 10px;
                display: inline-block;
                @include fontRem(18, 500);
                line-height: 22px;
                transition: 0.3s;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
                &:active {
                    text-decoration: underline;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-image: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
                }
                @media all and (max-width: $smallTablet) {
                    @include fontRem(14, 500);
                } 
            }
            p {
                text-align: center;
                color: rgba(227, 230, 234, 0.32);
                @include fontRem(18, 400);
                line-height: 22px;
                @media all and (max-width: $smallTablet) {
                    @include fontRem(14, 500);
                } 
            }
        }
    }
    .loader {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 12;
        background: rgba(41, 52, 139, 0.24);
        backdrop-filter: blur(16px);
    }
}
