@import "/src/styles/vars";
@import "/src/styles/mixins";

.portalHeader {
  padding-top: 40px;
  @media all and (max-width: $tablet) {
    padding-top: 40px;
  }
  p {
    max-width: 790px;
    @include fontRem(24, 400);
    line-height: 35px;
    .portalHeaderLink {
      cursor: pointer;
      color: $blue;
      transition: 0.3s;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &:active {
        text-decoration: underline;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
      }
    }
    @media all and (max-width: $tablet) {
      @include fontRem(14, 400);
      line-height: 22px;
      max-width: 288px;
      margin-bottom: 50px;
    }
  }
}
.portal {
  @include flex-center;
  padding-bottom: 140px;
  @media all and (max-width: $tablet) {
    padding-bottom: 40px;
  }
  .wrapperPortal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: transparent;
    max-width: 700px;
    svg {
      max-width: 100%;
      height: auto;
      margin-top: 120px;
      @media all and (max-width: $tablet) {
        margin-top: 0;
      }
    }
  }
}
