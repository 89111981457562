@import "/src/styles/vars";
@import "/src/styles/mixins";

.contactPage {
    width: 100%;
    min-height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../Assets/images/contact-about-bg.png"),
        linear-gradient(170.79deg, #151a2c 0.37%, #120d20 93.02%);
}
.contactSection {
    padding: 100px 0;
    @media all and (max-width: $tablet) {
        padding: 50px 10px;

    }
    .contactRow {
        @include flexRow(50, 2, flex-start);
        @media all and (max-width: $tablet) {
            @include flexRow(50, 1, flex-start);
        } 
        .contactInfo {
            [class*="title"] {
                text-align: left;
                @media all and (max-width: $tablet) {
                    margin-left: 0;
                } 
            }
            [class*="subTitle"] {
                text-align: left;
                max-width: 514px;
                margin-left: 0;
            }
        }
        .contactPageForm {
            background: rgba(41, 52, 139, 0.24);
            border-radius: 8px;
            padding: 56px 57px;
            @media all and (max-width: $tablet) {
                margin-top: 52px;
                padding: 43px 8px;
            }

        }
    }
}
