@import "/src/styles/vars";
@import "/src/styles/mixins";

.privacyPage {
    width: 100%;
    min-height: 100%;
    background: linear-gradient(170.79deg, #151a2c 0.37%, #120d20 93.02%);
}
.privacySection {
    padding: 64px 0 100px;
    @media all and (max-width: $normalDesktop) {
        padding: 64px 0;         
    }
    @media all and (max-width: $tablet) {
        padding-left: 10px;
        padding-right: 10px;       
    }
    
    @include flex-center;
    .privacyItem {
        background: rgba(41, 52, 139, 0.24);
        border-radius: 8px;
        overflow: hidden;
        padding: 50px 100px;
        @media all and (max-width: $normalDesktop) {
            padding: 20px 30px;
        }
        @media all and (max-width: $tablet) {
            padding: 32px 8px;     
        }
        .privacyTitle {
            margin-left: 0;
            text-align: left;
        }
        .privacyText {
            @include fontRem(18, 400);
            line-height: 27px;
            color: $white;
            @media all and (max-width: $normalDesktop) {
                @include fontRem(16, 400);
                line-height: 22px;
            }
            @media all and (max-width: $tablet) {
                @include fontRem(14, 400);   
                line-height: 22px;
            }
        }
    }
}
