@import "/src/styles/vars";
@import "/src/styles/mixins";

.settingsSection {
    padding: 60px 72px;
    background: rgba(41, 52, 139, 0.16);
    border-radius: 8px;
    position: relative;
    @media all and (max-width: $tablet) {
        padding: 25px 30px;
    } 
    @media all and (max-width: $smallTablet) {
        padding: 24px 8px;
    } 
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 8px;
        padding: 1px;
        background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
}
.settingsCollapseItem {
    position: relative;
    z-index: 1;
    .settingsCollapseRow {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .settingsCollapseCol {
            width: 100%;
            flex: 0 0 100%;
            &:not(:first-child) {
                margin-top: 34px;
            }
            &:not(:last-child) {
                .settinsCollapseItem {
                    border-bottom: 1px solid rgba(182, 193, 255, 0.12);
                }
            }
        }
        .settinsCollapseItem {
            padding-bottom: 30px;
            overflow: hidden;
            @media all and (max-width: $smallTablet)  {
                padding-bottom: 24px;
            }
            .settinsCollapseItemHeader {
                display: flex;
                align-items: flex-start;
                @media all and (max-width: $tablet)  {
                    justify-content: space-between;
                }
            }

            .collapseContent {
                max-width: 410px;
                margin-left: 250px;
                height: 0;
                overflow: hidden;
                transition: height 0.3s ease;
                padding: 0 30px;
                @media all and (max-width: $tablet)  {
                    margin-left: 0;
                    max-width: 75%;
                    padding: 0;
                } 
                @media all and (max-width: $smallTablet)  {
                    margin-left: 0;
                    max-width: 100%;
                    padding: 0;
                } 
                .collapseContentInner {
                    @include flex-column;
                    flex: 1 1 auto;
                    margin-top: 32px;
                    @media all and (max-width: $smallTablet)  {
                        margin-top: 8px;
                    }
                    [class*="selectList"] {
                        max-width: 100%;
                        z-index: 12;
                        background: #212149;
                        box-shadow: 4px 8px 24px rgba(19, 20, 38, 0.16);
                        backdrop-filter: blur(16px);
                    }
                    [class*="formItem"] {
                        margin-bottom: 24px;
                    }
                }
                .buttons {
                    @include flexRow(8, 2, center);
                    button {
                        padding: 13px 30px;
                    }
                    .bordered_btn {
                        background: transparent;
                        border-radius: 8px;
                        position: relative;
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border-radius: 8px;
                            padding: 2px;
                            background: linear-gradient(rgba(24, 117, 252, 1), rgba(0, 221, 251, 1));
                            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                            mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                            -webkit-mask-composite: xor;
                            mask-composite: exclude;
                        }
                    }
                }
            }
            .settinsCollapseTitle {
                width: 200px;
                @include fontRem(20, 600);
                line-height: 24px;
                color: $titleColor;
                @media all and (max-width: $normalDesktop) {
                    @include fontRem(18, 600);
                    width: 180px;
                }
                @media all and (max-width: $tablet) {
                    @include fontRem(18, 600);
                }
            }
            .settinsCollapsetext {
                max-width: 487px;
                width: 100%;
                margin-left: 80px;
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                p{
                    max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                }
                @media all and (max-width: $normalDesktop) {
                    margin-left: 50px;
                    max-width: 350px;
                }
                @media all and (max-width: $tablet) {
                    margin-left: 0;
                    margin-top: 8px;
                    max-width: 292px;
                }
                @media all and (max-width: $smallTablet) {
                    margin-left: 0;
                    max-width: 100%;
                    margin: 8px 0 10px;
                }
                p {
                    @include fontRem(16, 400);
                    @media all and (max-width: $normalDesktop) {
                        @include fontRem(14, 400);
                    }
                    line-height: 24px;
                    color: rgba(251, 252, 255, 0.72);
                }
            }
            .languageName {
                margin-left: auto;
                margin-right: auto;
                @include fontRem(20, 500);
                line-height: 24px;
                color: $titleColor;
                @media all and (max-width: $tablet) {
                    @include fontRem(18, 500);
                    margin-right: 56px;
                } 
            }
            .collpaseBtnContainer {
                display: flex;
                margin-left: auto;
                @media all and (max-width: $tablet) {
                    margin-left: 0;
                } 
                button {
                    margin-left: auto;
                    @include fontRem(18, 500);
                    line-height: 22px;
                    display: inline-block;
                    @include buttonNulled;
                    color: $blue;
                }
            }
        }
    }
}
