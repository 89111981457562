@use "sass:math";
@import "/src/styles/vars";
@import "/src/styles/mixins";
@import "../../../Assets/styles/locale.scss";


.toltipContent {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    position: absolute;
    top: 10px;
    left: 74px;
    width: 334px;
    border-radius: 8px;
    z-index: 999;
    border: 1px solid rgba(178, 186, 215, 0.24);

    .tooltipArrow {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(178, 186, 215, 0.24);
      transform: rotate(45deg);
      left: -10px;
      top: 10px;
      z-index: 3;
      background-color: #22233e;
    }
    p {
      background-color: #22233e;
      @include fontRem(14, 400);
      line-height: 22px;
      color: $white;
      text-align: left;
      position: relative;
      border-radius: 8px;
      z-index: 4;
      padding: 6px 13px;
    }
    &.toltipContentSmall {
      width: calc(334px / 2);
    }
    &.toltipContentBig {
      width: calc(334px * 2);
    }
  }



.toltipContentDashboard {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  top: 100%;
  left: 0;
  width: 184px;
  border-radius: 8px;
  z-index: 999;
  border: 1px solid rgba(178, 186, 215, 0.24);
  transform: translateY(20px);
  .tooltipArrow {
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(178, 186, 215, 0.24);
      left: 50%;
      top: -10px;
      transform: translateX(-50%) rotate(45deg);
      z-index: 3;
      background-color: #22233e;
  }
  p {
      background-color: #22233e;
      @include fontRem(14, 400);
      line-height: 22px;
      color: $white;
      text-align: left;
      position: relative;
      border-radius: 8px;
      z-index: 4;
      padding: 6px 13px;
  }
}

.open {
  visibility: visible !important;
  opacity: 1 !important;
}