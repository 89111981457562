@import "/src/styles/vars";
@import "/src/styles/mixins";
.modalTitle {
    text-align: center;
    @include fontRem(28, 700);
    line-height: 36px;
    color: $titleColor;
    @media all and (max-width: $smallTablet) {
        @include fontRem(20, 700);
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        line-height: 30px;
    }
}
.detailsList {
    margin-top: 50px;
    padding-left: 16px;
    padding-right: 16px;
    @media all and (max-width: $smallTablet) {
        padding-left: 0;
        padding-right: 0;
    }
    .detailsItemContent {
        margin-bottom: 40px;
        .divider {
            position: relative;
            top: 18px;
            width: 26px;
            margin: 0 15px;
            border: 1px solid rgba(41, 52, 139, 0.88);
            @media all and (max-width: $smallTablet) {
                width: 12px;
                margin: 0 2px;
            }
        }
        .label {
            @include fontRem(16, 500);
            line-height: 20px;
            margin-bottom: 16px;
            color: rgba(251, 252, 255, 0.64);
            display: block;
            @media all and (max-width: $smallTablet) {
                @include fontRem(14, 500);
            }
        }
        [class*="selectList"] {
            max-width: 100%;
            z-index: 12;
            background: #212149;
            box-shadow: 4px 8px 24px rgba(19, 20, 38, 0.16);
            backdrop-filter: blur(16px);
        }
        [class*="formItem"] {
            margin-bottom: 0;
            label,
            [class*="spanText"] {
                color: rgba(251, 252, 255, 0.64);
            }
        }
        .leverageItem {
            background: rgba(41, 52, 139, 0.24);
            border: 1px solid rgba(178, 186, 215, 0.32);
            box-sizing: border-box;
            border-radius: 8px;
            height: 64px;
            @include flex-center-vert;
            justify-content: space-between;
            padding: 13px 16px;
            @media all and (max-width: $smallTablet) {
                height: 48px;
            }
            .leverageMinus,
            .leverageplus {
                @include buttonNulled;
                @include fontRem(32, 500);
                line-height: 39px;
                color: $titleColor;
                @media all and (max-width: $smallTablet) {
                    @include fontRem(20, 500);
                    line-height: 24px;
                }
                &:disabled {
                    color: rgba(251, 252, 255, 0.64);
                }
            }
            .leverageCount {
                @include fontRem(22, 500);
                line-height: 27px;
                color: $titleColor;
                @media all and (max-width: $smallTablet) {
                    @include fontRem(16, 500);
                    line-height: 20px;
                }
            }
        }
        .inputsItems {
            @include flex-center-vert;
            .inputsItem {
                flex: 1;
                .inputsItemPrice{
                    margin-bottom: 13px;
                }
            }
        }
        .leverageRange {
            margin-top: 24px;
            input {
                width: 100%;
                appearance: none;
                -webkit-appearance: none;
                position: relative;
                overflow: hidden;
                height: 8px;
                width: 100%;
                cursor: pointer;
                border-radius: 8px;
                pointer-events: all;
                @media all and (max-width: $smallTablet) {
                    height: 4px;
                }
                &::-webkit-slider-runnable-track {
                    background: rgba(41, 52, 139, 1);
                    border-radius: 8px;
                }
                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    width: 0;
                    height: 8px;
                    background: $white;
                    border: 0;
                    box-shadow: -100vw 0 0 100vw $blue;
                    border-radius: 8px;
                    @media all and (max-width: $smallTablet) {
                        height: 4px;
                    }
                }
                &::-moz-range-track {
                    height: 8px;
                    border-radius: 8px;
                    background: rgba(41, 52, 139, 1);
                    @media all and (max-width: $smallTablet) {
                        height: 4px;
                    }
                }
                &::-moz-range-thumb {
                    background: $white;
                    height: 8px;
                    width: 0;
                    border: 0;
                    border-radius: 8px !important;
                    box-shadow: -100vw 0 0 100vw $blue;
                    box-sizing: border-box;
                    @media all and (max-width: $smallTablet) {
                        height: 4px;
                    }
                }
                &::-ms-fill-lower {
                    background: $blue;
                }
                &::-ms-thumb {
                    background: $white;
                    border: 0;
                    height: 8px;
                    width: 0;
                    box-sizing: border-box;
                    @media all and (max-width: $smallTablet) {
                        height: 4px;
                    }
                }
                &::-ms-ticks-after {
                    display: none;
                }

                &::-ms-ticks-before {
                    display: none;
                }
                &::-ms-tooltip {
                    display: none;
                }
                &::-ms-track {
                    background: $blue;
                    color: transparent;
                    height: 8px;
                    border: 0;
                }
            }
            .leverageSum {
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                    position: relative;
                    padding-top: 7px;
                    color: $titleColor;
                    @include fontRem(16, 500);
                    line-height: 20px;
                    @media all and (max-width: $smallTablet) {
                        @include fontRem(12, 500);
                        color: rgba(251, 252, 255, 0.64);
                    }
                    &.active {
                        &::after {
                            width: 18px;
                            height: 18px;
                            background-color: $blue;
                            border: 0;
                            top: -16px;
                            @media all and (max-width: $smallTablet) {
                                width: 12px;
                                height: 12px;
                                top: -12px;
                            }
                        }
                    }
                    &:first-child {
                        &::after {
                            left: 0;
                            transform: translateX(-10%);
                        }
                    }
                    &:last-child {
                        &::after {
                            left: auto;
                            right: 0;
                            transform: translateX(0);
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: -14px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 14px;
                        height: 14px;
                        background: #191c3e;
                        border: 2px solid rgba(41, 52, 139, 0.44);
                        pointer-events: none;
                        border-radius: 50%;
                        transition: width 0.3s, height 0.3s;
                        @media all and (max-width: $smallTablet) {
                            width: 10px;
                            height: 10px;
                            top: -11px;
                        }
                    }
                }
            }
        }
        .dropdownsRow {
            @include flexRow(7, 3, flex-start);
            @media all and (max-width: $smallTablet) {
                @include flexRow(2, 3, flex-start);
            }
        }
        .customSwich {
            display: inline-block;
            max-width: 115px;
            width: 100%;
            margin-left: auto;
            display: flex;
            margin-bottom: 16px;
            input {
                display: none;
                &:checked {
                    + label {
                        &::after {
                            left: 58px;
                        }
                    }
                }
            }
            label {
                width: 100%;
                cursor: pointer;
                @include flexRow(5, 2, center);
                border: 1px solid #215ae7;
                border-radius: 100px;
                height: 32px;
                position: relative;
                p {
                    position: relative;
                    z-index: 1;
                    text-align: center;
                    @include fontRem(16, 500);
                    line-height: 20px;
                    color: $white;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 1px;
                    background: #215ae7;
                    border-radius: 100px;
                    width: 54px;
                    height: 28px;
                    transition: 0.3s;
                }
            }
        }
    }
    .modalBtn {
        margin-top: 10px;
        button {
            margin-left: auto;
            margin-right: auto;
            width: auto;
            min-width: 232px;
            @media all and (max-width: $smallTablet) {
                width: 100%;
                height: 48px;
            }
        }
    }
}
