@use "sass:math";
@import "/src/styles/vars";
@import "/src/styles/mixins";
@import "../../../Assets/styles/locale.scss";


.howWorksItem {
    position: relative;
    background: rgba(41, 52, 139, 0.24);
    backdrop-filter: blur(16px);
    border-radius: 16px;
    text-align: center;
    padding: 0 16px;
    height: 230px;
    @media all and (max-width: $normalDesktop) {
     padding: 0 11px;
    }
    @media all and (max-width: $tablet) {
     height: 130px;
    }
    
    @supports (
      mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(
        #fff 0 0,
      )
    ) {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 16px;
        padding: 1px;
        background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
    .howWorksIcon {
      transform: translateY(-50%);
    }
    .howWorkstext {
      p {
        max-width: 310px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        @include fontRem(22, 400);
        line-height: 30px;
        color: $titleColor;
        @media all and (max-width: $tablet) {
          @include fontRem(16, 400);
          line-height: 19px;
          margin-top: -30px;
        } 
      }
    }
  }

  
  