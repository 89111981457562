@use "sass:math";
@import "/src/styles/vars";
@import "/src/styles/mixins";
@import "../../../Assets/styles/locale.scss";
.whyWeSection {
  position: relative;
  padding-top: 0;
  @media all and (max-width: $tablet) {
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 782px;
    height: 750px;
    z-index: 0;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='782' height='817' viewBox='0 0 782 817' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M88.844 0L88.844 817M308.028 0L308.028 817M527.212 0V817M746.396 0L746.396 817M965.58 0L965.58 817M0 45.2705H1064M0 226.353H1064M0 407.435H1064M0 588.517H1064M0 769.599H1064' stroke='url(%23paint0_radial_506_85)' stroke-opacity='0.48' stroke-width='4'/%3E%3Cdefs%3E%3CradialGradient id='paint0_radial_506_85' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(532 408.5) scale(532 694.387)'%3E%3Cstop stop-color='%23B6C1FF'/%3E%3Cstop offset='0.46875' stop-color='%23A2B0FF' stop-opacity='0.37'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%239AAAFF' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
    @media all and (max-width: $tablet) {
      content: none;
    }
  }
}
.howItWorksSection {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  z-index: 2;
  &::after {
    content: "";
    width: 1064px;
    height: 817px;
    position: absolute;
    left: 0;
    top: 100px;
    background-repeat: no-repeat;
    opacity: 0.1;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='882' height='817' viewBox='0 0 882 817' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-93.156 0L-93.156 817M126.028 0L126.028 817M345.212 0V817M564.396 0L564.396 817M783.58 0L783.58 817M-182 45.2705H882M-182 226.353H882M-182 407.435H882M-182 588.517H882M-182 769.599H882' stroke='url(%23paint0_radial_537_41)' stroke-opacity='0.32' stroke-width='4'/%3E%3Cdefs%3E%3CradialGradient id='paint0_radial_537_41' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(350 408.5) scale(532 694.387)'%3E%3Cstop stop-color='%23B6C1FF'/%3E%3Cstop offset='0.46875' stop-color='%23A2B0FF' stop-opacity='0.37'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%239AAAFF' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
    @media all and (max-width: $normalDesktop) {
      content: none;
    }
  }
}
.discountSection {
  position: relative;
  padding-top: 60px;
  @media all and (max-width: $mobile) {
    padding-left: 6px;
    padding-right: 6px;
  }
  .discountMain {
    background: rgba(41, 52, 139, 0.16);
    backdrop-filter: blur(34px);
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin-bottom: 60px;
  }
  .discrountItem {
    padding: 40px 0 50px;
    background: rgba(41, 52, 139, 0.16);
    backdrop-filter: blur(34px);
    border-radius: 16px;
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("../../../Assets/images/dicount-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.5;
      width: 40%;
    }
    .discountTitle {
      text-align: center;
      span {
        @include fontRem(54, 700);
        line-height: 66px;
        letter-spacing: 0.055em;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
        @media all and (max-width: $normalDesktop) {
          @include fontRem(40, 700);
          line-height: 48px;
        }
      }
    }
    @supports (
      mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(
        #fff 0 0,
      )
    ) {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 16px;
        padding: 1px;
        background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
  }
  .discountText {
    max-width: 617px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    @include fontRem(18, 400);
    line-height: 24px;
    color: $titleColor;
    text-align: center;
    @media all and (max-width: $mobile) {
      margin-top: 16px;
      @include fontRem(14, 400);
      max-width: 320px;
    }
  }
  .discountBtn {
    width: unset;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    @media all and (max-width: $mobile) {
      width: 212px;
      height: 48px;
    }
  }
}
.cardsSection {
  position: relative;
  padding-top: 60px;
  background-repeat: no-repeat;
  background-position: top left;
}
.uptSection {
  .uptContainer {
    position: relative;
    padding: 74px 147px;
    background-image: url("../../../Assets/images/upt-bg.png");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    border-radius: 16px;
    @media all and (max-width: $normalDesktop) {
      padding: 72px 33px;
    }
    @media all and (max-width: $tablet) {
      padding: 46px 18px;
    }
    @media all and (max-width: $smallTablet) {
      background-position: top right;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px;
      padding: 1px;
      background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
    .uptTitle {
      text-align: center;
      span {
        @include fontRem(54, 700);
        line-height: 66px;
        letter-spacing: 0.055em;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
        @media all and (max-width: $normalDesktop) {
          @include fontRem(40, 700);
          line-height: 48px;
        }
      }
      @media all and (max-width: $tablet) {
        max-width: unset;
        span {
          @include fontRem(24, 700);
        }
      }
      @media all and (max-width: $smallTablet) {
        max-width: 260px;
      }
    }
    .uptSubtext {
      max-width: 788px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 24px;
      @include fontRem(18, 400);
      line-height: 24px;
      color: $titleColor;
      text-align: center;
      @media all and (max-width: $mobile) {
        margin-top: 16px;
        @include fontRem(14, 400);
        max-width: 320px;
      }
    }
    .uptSectionBody {
      margin-top: 72px;
      @media all and (max-width: $smallTablet) {
        @include flexRow(8, 1, center);
      }
      .uptSectionRow {
        @include flex-center;
        @include flexRow(30, 3, center);
        @media all and (max-width: $normalDesktop) {
          @include flexRow(24, 3, center);
        }
        @media all and (max-width: $tablet) {
          @include flexRow(8, 3, center);
        }
        @media all and (max-width: $smallTablet) {
          @include flexRow(8, 1, center);
        }
      }
      .uptSectionItem {
        position: relative;
        padding: 30px 62px 30px;
        background: rgba(41, 52, 139, 0.24);
        backdrop-filter: blur(16px);
        border-radius: 16px;
        min-height: 190px;
        @media all and (max-width: $normalDesktop) {
          padding: 22px 30px;
        }
        @media all and (max-width: $tablet) {
          padding: 10px 10px;
        }
        @media all and (max-width: $smallTablet) {
          margin-bottom: 24px;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 16px;
          padding: 1px;
          background: linear-gradient(rgba(178, 186, 215, 0.24), rgba(157, 164, 189, 0.02));
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
        }
        .uptSectionTitle {
          @include fontRem(28, 700);
          line-height: 34px;
          text-align: center;
          color: $titleColor;
          @media all and (max-width: $tablet) {
            @include fontRem(24, 700);
          }
          span {
            letter-spacing: 0.055em;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(229.09deg, #1875fc 3.76%, #00ddfb 115.42%);
            @media all and (max-width: $normalDesktop) {
              @include fontRem(40, 700);
              line-height: 48px;
            }
            @media all and (max-width: $tablet) {
              @include fontRem(24, 700);
            }
          }
        }
        .uptSectionText {
          text-align: center;
          color: $titleColor;
          margin-top: 24px;
          @include fontRem(18, 400);
          line-height: 24px;
          span {
            font-weight: 600;
          }
        }
      }
    }
    .uptSectionBtn {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 90px;
      @media all and (max-width: $mobile) {
        margin-top: 20px;
      } 
      button {
        max-width: 232px;
        @media all and (max-width: $mobile) {
          padding: 15px 20px;
          max-width: 100%;
        }
      }
    }
  }
}
